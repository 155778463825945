<template>
  <div>
    <a-modal
      width="800px"
      v-model="isShow"
      centered
      :mask-closable="false"
      title="VPDN分组"
    >
      <template slot="footer">
        <a-button key="back" @click="handleCancel"> 关闭 </a-button>
      </template>
      <div />

      <a-spin :spinning="loading">
        <a-row :gutter="24">
          <a-descriptions
            title=""
            :column="1"
            class="simiot-descriptions simiot-descriptions-max-6"
          >
            <a-descriptions-item label="白名单豁免">
              <label class="margin-left-30">{{
                settings.is_white_list_exemption ? "开启" : "关闭"
              }}</label><label class="margin-left-60">99999_E</label>
            </a-descriptions-item>

            <a-descriptions-item label="定转通权限">
              <label class="margin-left-30">{{
                settings.is_fixed_transfer ? "开启" : "关闭"
              }}</label><label class="margin-left-60">99999_A</label>
            </a-descriptions-item>
          </a-descriptions>
        </a-row>

        <div>
          <a-form :form="form" class="simiot-nested-search-form">
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-item label="VPDN分组名称">
                  <a-input
                    v-decorator="[
                      'name',
                      {
                        normalize: this.$lodash.trim,
                      },
                    ]"
                    allow-clear
                  />
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item label="VPDN分组ID">
                  <a-input
                    v-decorator="[
                      'vpdn_id',
                      {
                        normalize: this.$lodash.trim,
                      },
                    ]"
                    allow-clear
                  />
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item>
                  <a-space>
                    <a-button class="search" @click="handleSearch"> 搜索 </a-button>
                    <a-button @click="handleReset"> 刷新 </a-button>
                  </a-space>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>

        <div class="padding-10" :gutter="24">
          <a-table
            size="middle"
            :columns="columns"
            :bordered="false"
            :data-source="data"
            :loading="loading"
            :pagination="false"
            :scroll="{ y: 300 }"
            row-key="name"
            class="vpdn-ips-index"
            :row-class-name="rowBackGround"
          />
        </div>

        <div>共 {{ data.length }} 条记录</div>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findVpdnsAll } from '@/api/vpdn'
import { findAgentSettings } from '@/api/agent'

export default {
  name: 'VpdnsList',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      ip: '',
      form: this.$form.createForm(this),
      data: [],
      loading: true,
      settings: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    columns() {
      return [
        {
          title: 'VPDN分组名称',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: 'VPDN分组ID',
          dataIndex: 'vpdn_id',
          key: 'vpdn_id'
        },
        {
          title: 'VPDN IP数',
          dataIndex: 'ips_count',
          key: 'ips_count'
        }
      ]
    }
  },
  created() {
    this.fetchData()
    this.fetchSettingsData()
  },
  methods: {
    rowBackGround(record, index) {
      return index % 2 === 0 ? 'ip-background no-border' : 'no-border'
    },

    fetchSettingsData() {
      this.loading = true
      findAgentSettings(this.id).then((res) => {
        if (res.code === 0) {
          this.settings = res.data
        }
        this.loading = false
      })
    },

    fetchData() {
      this.loading = true
      findVpdnsAll(Object.assign({ agent_id: this.id }, this.form.getFieldsValue())).then(
        (res) => {
          this.data = res.data
          this.loading = false
        }
      )
    },

    handleCancel() {
      this.isShow = false
    },

    handleReset() {
      this.form.resetFields()
      this.fetchData()
    },

    handleSearch() {
      this.fetchData()
    }
  }
}
</script>
<style lang="less">
.padding-10 {
  padding: 10px;
}

.margin-left-60 {
  margin-left: 60px;
}

.margin-left-30 {
  margin-left: 30px;
}

.search {
  background-color: #46a6ff;
  border-color: #46a6ff;
  color: #fff;
}

.vpdn-ips-index .ant-table-thead > tr > th {
  background: #fff;
  font-family: "PingFangSC-Semibold", "PingFang SC Semibold", "PingFang SC";
  font-weight: 650;
  padding-bottom: 5px;
  border-bottom: none !important;
}

.no-border td {
  border-bottom: none !important;
}

.ip-background {
  background: #dae1e7;
}
</style>
